
.prompt_popup {
    background-color: rgba(0, 0, 0, 0.7);
    padding: var(--space-sm);
}

.prompt_popup_content {
    // We need more specificity.
    &#{&} {
        &#{&} {
            width: 100%;
            max-width: 600px;
        }
    }
}
