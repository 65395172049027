
.container {
    background-color: rgba(0, 0, 0, 0.7);

    .contents {
        width: 100%;
        max-width: 540px;
        padding: var(--space-xs);
    }
}
