@use "@/scss/config" as *;

.desc {
    font-size: var(--text-base);
}

.buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--space-sm);
}
